body {
  color: #636363;
}

.page-title,
.sub-page-title {
  text-align: left;
  letter-spacing: 0;
  color: #1351b4;
  opacity: 1;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 1px solid var(--color-gray-4);
}

.sub-page-title {
  margin-top: 40px;
  color: #636363;
  font-size: 1.1rem;
  display: block;
  width: 70%;
}

.page-description {
  margin-top: 4%;
  margin-bottom: 2%;
  color: #636363;
}

.back-icon {
  border-radius: 50%;
  padding: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  float: left;
}
.back-icon:hover {
  background-color: rgba(19, 81, 180, 0.2);
}

.back-icon svg {
  width: 10px;
}

.container-cadastro {
  display: flex;
  flex-wrap: wrap;
}

.container-foto {
  flex: 1 0 50%;
}

.container-senha {
  flex: 1 0 50%;
}

.form-panel-icon {
  margin: 1.7rem 1rem 0.5rem 0.3rem;
}

.form-panel {
  display: inline-block;
  width: 100%;
}

.form-panel label {
  font-size: 0.8rem;
  color: #155bcb;
  margin: 0;
}

.form-panel-fields {
  float: right;
  width: 90%;
  margin: 10px 0;
}

#lvl-button {
  font-family: 'rawline', Rawline, sans-serif;
  background: #ffffff;
  border: 1px solid var(--color-primary);
  border-radius: 100px;
  cursor: pointer;
  padding: 0.5em 1em;
  font-size: 0.7em;
  text-align: center;
  font-weight: 600;
  color: var(--color-primary);
  margin: 0;
}

.aumentar-lvl-button::before {
  content: url(/images/icone_aumentar-nivel_2.svg);
  padding-right: 5px;
  vertical-align: middle;
}

.ver-lvl-button::before {
  padding-right: 5px;
  font-family: FontAwesome;
  content: '\f06e';
}

#lvl-button-primary {
  font-family: 'rawline', Rawline, sans-serif;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 100px;
  cursor: pointer;
  padding: 1em 2em 0.7em 2em;
  font-size: 0.7em;
  text-align: center;
  font-weight: 600;
  color: white;
  margin-top: 20px;
  vertical-align: middle;
}

.aumentar-lvl-button-primary::before {
  content: url(/images/icone_aumentar-nivel.svg);
  padding-right: 5px;
  filter: brightness(0) invert(1);
  vertical-align: middle;
}

.ver-lvl-button-primary::before {
  font-family: FontAwesome;
  content: '\f06e';
  color: #ffffff;
  padding-right: 5px;
}

@media (max-width: 965px) {
  .form-panel {
    margin-bottom: 20px;
  }
  .form-panel-icon {
    margin: 1.7rem 1rem 0.5rem 0;
  }
  .form-panel-fields {
    float: right;
    width: 85%;
  }
  input[type='text'],
  input[type='password'],
  input[type='email'] {
    width: 85%;
  }
}

@media (max-width: 764px) {
  input[type='text'],
  input[type='password'],
  input[type='email'] {
    width: 75%;
  }

  .sub-page-title {
    width: 100%;
  }
}

@media (max-width: 600px) {
  input[type='text'],
  input[type='password'],
  input[type='email'] {
    width: 80%;
  }
}
