header {
  font-family: rawline, sans-serif;
  font-size: 10pt;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 14%;
  padding-left: 14%;
}

i {
  color: var(--color-primary);
  cursor: pointer;
}

.caixa-alta {
  text-transform: uppercase;
  color: rgb(99, 99, 99);
}

.link-nivel {
  text-decoration: none;
  color: rgb(99, 99, 99);
}

.topnav #linksgoverno a,
.topnav i,
.topnav div.dropdown {
  float: right;
}

.topnav #linksgoverno a,
.topnav img#logomarca,
.topnav i {
  display: block;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: 13px;
}

.topnav img#logomarca {
  padding: 0;
  margin-top: 20px;
  float: left;
  width: 111px;
  cursor: pointer;
}

.topnav i {
  font-size: 22px;
}

.topnav #icon {
  display: none;
}

button#btnMenu {
  background-color: white;
  border: 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 18px;
}

button#btnMenu::after {
  font-family: 'FontAwesome';
  content: '\F107';
  font-size: 25px;
  color: #1351b4;
  font-weight: 900;
  margin-left: 4px;
}

button#btnMenu:hover {
  background-color: rgba(19, 81, 180, 0.16);
  border-radius: 100em;
  padding-bottom: 5px;
}

.avatar-pic {
  height: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  bottom: -3px;
  position: relative;
}

img#imglevel {
  width: 100% !important;
  max-width: none !important;
  float: none !important;
  margin: 1em 0 0 0 !important;
  padding-bottom: 15px;
}

ul.avatarmenu {
  padding: 0;
}
.avatarmenu li {
  list-style-type: none;
  padding: 4% 6% 4% 0 !important;
}

.avatarmenu li a {
  font-weight: 600;
}

.dropdown-content {
  background-color: #ffffff;
  min-width: 20rem;
  color: #666666;
  border-radius: 1em;
  border: none;
  box-shadow: 0 0.2em 0.3em 0.01em #ccc;
  top: 70px;
}

.dropdown-content p {
  font-size: 17.33px;
  margin: 0.5em 0 0.5em 0;
}

.dropdown-content p strong {
  font-weight: 700;
}

.dropdown-content span {
  font-weight: 600;
}

.dropdown-content button#btnLogout {
  float: right;
}

.dropdown-content hr {
  margin-bottom: 0;
}

.btn-secondary {
  font-family: 'rawline', Rawline, sans-serif;
  background: #ffffff;
  border: 1px solid var(--color-primary);
  border-radius: 100px;
  cursor: pointer;
  padding: 0.3em 1em;
  font-size: 13.33px;
  text-align: center;
  font-weight: 600;
  color: var(--color-primary);
  margin: 10px 10px 10px 0;
  width: fit-content;
  width: -moz-fit-content;
}

@media screen and (max-width: 1024px) {
  header {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .topnav #linksgoverno a {
    display: none;
  }

  .topnav i#icon {
    display: flow-root;
    margin: 28px;
    padding: 0;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive #linksgoverno a {
    float: none;
    display: flow-root;
    text-align: right;
    line-height: 0;
  }

  .topnav.responsive #linksgoverno a:first-of-type {
    margin-top: 83px;
  }

  .topnav.responsive #linksgoverno a:last-of-type {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 765px) {
  header {
    padding-right: 6%;
    padding-left: 6%;
  }
}

@media screen and (min-width: 1024px) {
  header {
    padding-right: calc((100% - 930px) / 2);
    padding-left: calc((100% - 910px) / 2);
  }
}

@media screen and (min-width: 1280px) {
  header {
    padding-right: calc((100% - 1080px) / 2);
    padding-left: calc((100% - 1080px) / 2);
  }
}

@media screen and (min-width: 1600px) {
  header {
    padding-right: calc((100% - 1380px) / 2);
    padding-left: calc((100% - 1380px) / 2);
  }
}
