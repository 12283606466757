input[type='text'] {
  margin: 5px 10px 0 0;
}

.container-loja-app {
  display: flex;
  flex-direction: column;
}

.container-loja-app-icons {
  display: flex;
  justify-items: stretch;
}

.container-loja-app-icons img {
  height: 38px;
}

.two-fa-info {
  display: flex;
}

.two-fa-info > img {
  flex-grow: 1;
  object-fit: contain;
  height: 165px;
  margin-top: 20px;
}

@media (max-width: 1300px) {
  .two-fa-info > img {
    display: none;
  }
}

@media (min-width: 700px) {
  .two-factor-authentication .action-secondary {
    width: 50%;
  }
  .two-factor-authentication .action-primary {
    width: 50%;
  }
}
